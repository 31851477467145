import React, { lazy, Suspense } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import "./assets/css/Style.css";
import "./assets/css/ModulesDesignChange.scss";
import "./assets/css/Bootstrap.min.css";
import "./assets/css/Common.scss";
import "react-confirm-alert/src/react-confirm-alert.css"; // autocomplete
import Footer from "./Components/subcomponents/footer";
import LeftNavi from "./Components/subcomponents/leftnavigation";
import loader from "./assets//images/loading.gif";
import Alert from "./Components/common/Alert";
import Header from "./Components/subcomponents/header";
import ScrollToTop  from "./Components/common/ScrollToTop"
const Home = lazy(() => import("./Components/Home"));
const Login = lazy(() => import("./Components/Login"));
const MemberDetails = lazy(() => import("./Components/Members/Detail"));
const MemberPassword = lazy(() => import("./Components/Members/Password"));
const UserEdit = lazy(() => import("./Components/Members/Edit"));
const UserList = lazy(() => import("./Components/Members/UserList"));
const PaymentList = lazy(() => import("./Components/Members/PaymentList"));
const EventAdd = lazy(() => import("./Components/Events/EventAdd"));
const PressReleaseAdd = lazy(() => import("./Components/PressRelease/Add"));
const EventList = lazy(() => import("./Components/Events/EventList"));
const EventMappedList = lazy(() => import("./Components/Events/EventMappedList"));
const OtherEventList = lazy(() => import("./Components/Events/OtherEventList"));
const EventListTop = lazy(() => import("./Components/Events/EventListTop"));
const VenueList = lazy(() => import("./Components/Venues/VenueList"));
const VenueAdd = lazy(() => import("./Components/Venues/VenueAdd"));
const Leads = lazy(() => import("./Components/Leads"));
const ContactHistory = lazy(() => import("./Components/ContactHistory"));
const ContactUsList = lazy(() => import("./Components/ContactUs/List"));
const Organizer = lazy(() => import("./Components/Organizer/List"));
const Speakers = lazy(() => import("./Components/Speakers/List"));
const OrganizerAdd = lazy(() => import("./Components/Organizer/Add"));
const OnlineEventAdd = lazy(() => import("./Components/Events/OnlineEventAdd"));
const PressReleaseList = lazy(() => import("./Components/PressRelease/List"));
const BotLogList = lazy(() => import("./Components/BotLog/List"));
const BotLogDetails = lazy(() => import("./Components/BotLog/Details"));
const MapEvent = lazy(() => import("./Components/Events/MapEvent"));
const FullMappedList = lazy(() => import("./Components/Events/FullMappedList"));
const AttendeeExhibitorList = lazy(() => import("./Components/Events/AttendeeExhibitorList"));
const CreateSchedule = lazy(() => import("./Components/Email/CreateSchedule"));
const ScheduleList = lazy(() => import("./Components/Email/ScheduleList"));
const Stats = lazy(() => import("./Components/Email/Stats"));
const AddNewsletter = lazy(() => import("./Components/Email/AddNewsletter"));
const EmailData = lazy(() => import("./Components/Email/FullData"));
const AddExhibitorList = lazy(() => import("./Components/Events/AddExhibitorList"));
const ExhibitorList = lazy(() => import("./Components/Events/ExhibitorList"));
const FontAwesome = require('react-fontawesome');
const App = (props) => {
  const windowScrollUpDown = (scrolPos = 0) => {
    window.scrollTo(0, scrolPos);
  }
  if (props.auth) {
    return (
      <div className={props.inProgress ? "disablePage" : ""}>
        {props.inProgress && (
          <div id="fullLoader">
            <img src={loader} />
          </div>
        )}
        <Alert message={props.alertMessage} severity={props.alertType} open={props.alertOpen} />
        <Header />
        <FontAwesome name='angle-up' className="scrollToTop" size='4x' onClick={windowScrollUpDown} />
        <FontAwesome name='angle-down' className="scrollToTop Down" size='4x' onClick={() => windowScrollUpDown(document.body.scrollHeight)} />
        <section className="userList">
          <div className="container-fluid inner">
            <div className="row">
              <BrowserRouter>
                <LeftNavi />
                <div className="topHeight"></div>
                <Suspense fallback={<div />}>
                <ScrollToTop>
                  <Switch>
                    <Route exact path={["/memberEdit/:id", "/memberAdd/:type"]} component={MemberDetails} />
                    <Route exact path={"/memberEdit/password/:id"} component={MemberPassword} />
                    <Route exact path={["/user/add", "/user/:id"]} component={UserEdit} />
                    <Route exact path={"/events"} component={EventList} />
                    <Route exact path={"/other-events"} component={OtherEventList} />
                    <Route exact path={"/events/top300"} component={EventListTop} />
                    <Route exact path={"/venues"} component={VenueList} />
                    <Route exact path={["/venue/add", "/venue/:venueID","/venue/:venueID/:flag"]} component={VenueAdd} />
                    <Route exact path={["/event/add", "/event/:eventID"]} component={EventAdd} />
                    <Route exact path={["/online-event/add", "/online-event/:eventID"]} component={OnlineEventAdd} />
                    <Route exact path={"/leads"} component={Leads} />
                    <Route exact path={"/contact-us-list"} component={ContactUsList} />
                    <Route exact path={"/users"} component={UserList} />
                    <Route exact path={"/speakers"} component={Speakers} />
                    <Route exact path={"/mappedList"} component={EventMappedList} />
                    <Route exact path={"/payment-details"} component={PaymentList} />
                    <Route exact path={"/pressRelease"} component={PressReleaseList} />
                    <Route exact path={["/pressRelease/add", "/pressRelease/:pressReleaseID"]} component={PressReleaseAdd} />
                    <Route exact path={["/botlogs"]} component={BotLogList} />
                    <Route exact path={["/botlogs/:botId"]} component={BotLogDetails} />
                    <Route exact path={["/events/map"]} component={MapEvent} />
                    <Route exact path={["/events/mapList"]} component={FullMappedList} />
                    <Route exact path={["/attendeeExhibitorList"]} component={AttendeeExhibitorList} />
                    <Route exact path={["/contact-history/:type/:common_id"]} component={ContactHistory} />
                    <Route exact path={"/email/createSchedule"} component={CreateSchedule} />
                    <Route exact path={"/email/scheduleList"} component={ScheduleList} />
                    <Route exact path={"/email/stats"} component={Stats} />
                    <Route exact path={"/email/addNewsletter"} component={AddNewsletter} />
                    <Route exact path={"/email/data"} component={EmailData} />
                    <Route exact path={"/events/addExhibitorList"} component={AddExhibitorList} />
                    <Route exact path={"/events/exhibitorList"} component={ExhibitorList} />
                    <Route exact path={"/organizers"} component={Organizer} />
                    <Route exact path={["/organizer/add", "/organizer/:organizerID"]} component={OrganizerAdd} />
                    {/* <Route exact path={["/speaker/add", "/speaker/:speakerID"]} component={SpeakerAdd} /> */}
                    <Route path="/" component={Home} />
                  </Switch>
                  </ScrollToTop>
                </Suspense>
              </BrowserRouter>
            </div>
          </div>
        </section>
        {/* <Footer /> */}
      </div>
    );
  } else {
    return (
      <div>
        {props.currentlySending && (
          <div id="fullLoader">
            <img src={loader} />
          </div>
        )}
        <Alert message={props.alertMessage} severity={props.alertType} open={props.alertOpen} />
        <BrowserRouter>
          <Switch>
            <Suspense fallback={<div />}>
              <Route path="/" component={Login} />
            </Suspense>
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    auth: state.authentication?.loggedIn,
    inProgress: state.common.inProgress,
    alertMessage: state.common.alertMessage,
    alertType: state.common.alertType,
    alertOpen: state.common.alertOpen,
    currentlySending: state.authentication.currentlySending,
  };
};

export default connect(mapStateToProps, null)(App);
