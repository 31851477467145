import React from "react";

const leftNavi = () => {
  return (
    <div className="col-lg-2">
      <div className="myToolsLeft Property">
        <div className="agentsleftMenu pt10 pt10">
          <h6 className="red">Users</h6>
          <ul className="mb10">
            <li>
              <a href="/user/add">Create</a>
            </li>
            <li>
              <a href="/users">List / Edit</a>
            </li>
            <li>
              <a href="/payment-details">Pro Users</a>
            </li>
          </ul>
        </div>

        <div className="agentsleftMenu pt10">
          <h6 className="red">Events</h6>
          <ul className="mb10">
            <li>
              <a href="/events">Event List</a>
            </li>
            <li>
              <a href="/event/add">Add Event</a>
            </li>
          </ul>
        </div>
        <div className="agentsleftMenu pt10">
          <h6 className="red">Exhibitor</h6>
          <ul className="mb10">
            <li>
              <a href="/events/addExhibitorList">Add Exhibitor List</a>
            </li>
            <li>
              <a href="/events/exhibitorList">Exhibitor List</a>
            </li>
          </ul>
        </div>
        <div className="agentsleftMenu pt10">
          <h6 className="red">Organizer</h6>
          <ul className="mb10">
            <li>
              <a href="/organizers">List</a>
            </li>
            <li>
              <a href="/organizer/add">Add</a>
            </li>
          </ul>
        </div>
        <div className="agentsleftMenu pt10">
          <h6 className="red">Venues</h6>
          <ul className="mb10">
            <li>
              <a href="/venues">Venue List</a>
            </li>
            <li>
              <a href="/venue/add">Add Venue</a>
            </li>
          </ul>
        </div>
        <div className="agentsleftMenu pt10">
          <h6 className="red">Event Mapping</h6>
          <ul className="mb10">
            <li>
              <a href="/mappedList">Mapped Event List</a>
            </li>
            <li>
              <a href="/events/mapList">Full Map List</a>
            </li>
            <li>
              <a href="/events/map">Map Events</a>
            </li>
          </ul>
        </div>
        <div className="agentsleftMenu pt10">
          <h6 className="red">Email - Newsletter</h6>
          <ul className="mb10">
            <li>
              <a href="#">Add Email</a>
            </li>
            <li>
              <a href="/email/addNewsletter">Add Newsletter</a>
            </li>
            <li>
              <a href="/email/createSchedule">Create Schedule</a>
            </li>
            <li>
              <a href="/email/scheduleList">Scheduled List</a>
            </li>
            <li>
              <a href="/email/stats">Stats & Report</a>
            </li>
            <li>
              <a href="/email/data">Total Details</a>
            </li>
          </ul>
        </div>
        
        <div className="agentsleftMenu pt10">
          <h6 className="red">Contacts</h6>
          <ul className="mb10">
            <li>
              <a href="/botlogs">Bot Logs </a>
            </li>
            <li>
              <a href="/contact-us-list">Contact Us </a>
            </li>
            <li>
              <a href="/leads">Leads </a>
            </li>
          </ul>
        </div>
        <div className="agentsleftMenu pt10">
          <h6 className="red">Press Release</h6>
          <ul className="mb10">
            <li>
              <a href="/pressRelease">List</a>
            </li>
            <li>
              <a href="/pressRelease/add">Add</a>
            </li>
          </ul>
        </div>
        <div className="agentsleftMenu pt10">
          <h6 className="red">Other Events</h6>
          <ul className="mb10">
            <li>
              <a href="/other-events">Internation Events</a>
            </li>
            <li>
              <a href="/attendeeExhibitorList">Stats List</a>
            </li>
            <li>
              <a href="/events/top300">Top 300 Events</a>
            </li>
          </ul>
        </div>

        {/* <div className="agentsleftMenu pt10">
          <h6 className="red">Suppliers</h6>
          <ul className="mb10">
            <li>
              <a href="#">Supplier List</a>
            </li>
            <li>
              <a href="#">Add / Edit Supplier</a>
            </li>
            <li>
              <a href="#">Search by Category</a>
            </li>
          </ul>
        </div> */}

        {/* <div className="agentsleftMenu pt10">
          <h6 className="red">Exhibitors</h6>
          <ul className="mb10">
            <li>
              <a href="#">Exhibitor List</a>
            </li>
            <li>
              <a href="#">Add / Edit Exhibitor</a>
            </li>
            <li>
              <a href="#">Search by Category</a>
            </li>
          </ul>
        </div> */}
        {/* <div className="agentsleftMenu pt10">
          <h6 className="red">Dashboard</h6>
          <ul className="mb10">
            <li>
              <a href="#">Events</a>
            </li>
            <li>
              <a href="#">Venues</a>
            </li>
            <li>
              <a href="#">Suppliers</a>
            </li>
            <li>
              <a href="#">Speakers</a>
            </li>
          </ul>
        </div> */}
      </div>
    </div>
  );
};

export default leftNavi;
